@import "variables.scss";

.heroContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: $primary_color;
    .logo{
        width: 40%;
    }
}

@media screen and (max-width: 800px) {
    .heroContainer {
        .logo{
            width: 80%;
        }
    }
}