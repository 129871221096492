@import "variables.scss";

.play, .pause {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    border: solid 3px $secondary_color !important;
    transform: scale(1.2);
    transition: all 200ms ease-in-out;
    &:hover {
        cursor: pointer;
        transform: scale(1.4);
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            border: solid 1px $secondary_color;
            transform: scale(1.35);
            border-radius: 100px;
            animation: grow 200ms ease-in-out;
        }
    }
    svg{
        transform: translateX(10%);
    }
}
.pause {
    svg{
        transform: translateX(0%) scale(0.9);
    }
}

@keyframes grow {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.35);
    }
}