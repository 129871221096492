@import "variables";

.musicContainer{
    display: flex;
    justify-content: space-around;
    background-color: $primary_color;
    padding: 2rem;
    padding-top: 5rem;
    border-radius: 30px;
    transform: translateY(-3%);
    box-shadow: 0px -4px 4px rgba(0,0,0,0.5);
    .playerContainer{
        position: relative;
        border-radius: 15px;
        width: 35%;
        height: 75vh;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
        background: linear-gradient(to bottom, 
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.2) 100%);
        overflow: hidden;
        .currentSongImage {
            position: absolute;
            inset: 0;
            // height: 100%;
            width: 100%;
            max-width:500px;
            max-height:1000px;
            width: auto;
            height: auto;
        }
        .currentSongTitle {
            position: absolute;
            top: 5%;
            left: 10%;
            font-size: 3.5rem;
            text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
            &.songPlaying {
                animation: playing 3s ease-in-out infinite;
            }
        }
        .controlsContainer {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            border-radius: 15px;
            height: 20%;
            // overflow: hidden;
            box-shadow: 0 -2px 5px rgba(0,0,0,0.5);
            .progressBarContainer {
                position: absolute;
                top: 0;
                transform: translateY(-10px);
                height: 10px;
                width: 90%;
                border-top-left-radius: 7px;
                border-top-right-radius: 7px;
                border: solid 1px $secondary_color;
                border-bottom: none;
                .progressBar {
                    height: 100%;
                    width: 0%;
                    background-color: $secondary_color;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    transition: all 200ms ease-in-out;
                }
            }
            &::after {
                content: "";
                position: absolute;
                inset: 0;
                background-color: rgba($primary_color,0.3);
                backdrop-filter: blur(5px);
                border-radius: 15px;
            }
            .controlButton {
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                border: none;
                z-index: 1;
                width: 50px;
                height: 50px;
                transition: all 200ms ease-in-out;
                &.previous, &.next {
                    transform: scale(0.8);
                    &:hover {
                        cursor: pointer;
                        transform: scale(0.9);
                    }
                }
            }
        }
    }
    .songsContainer {
        width: 50%;
        .headerContainer{
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            p{
                text-align: center;
                height: fit-content;
                margin: 0;
            }
        }
    }
}

@media (max-width: 600px) {
    .musicContainer {
        flex-direction : column;
        padding: 1rem;
        .playerContainer {
            width: 100%;
            height: 50vh;
            .currentSongTitle {
                font-size: 2rem;
            }
            .controlsContainer {
                height: 30%;
            }
        }
        .songsContainer {
            width: 100%;
            margin-top: 2rem;
        }
    }
}

@keyframes playing {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}