@import "variables.scss";

.navigationContainer{
    position: fixed;
    width: 100%;
    background-color: rgba($primary_color, 0.8);
    z-index: 2;
    .navElementsContainer{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 35%;
        min-width: 300px;
        padding: 1rem;
        .navElement, .navElement:visited {
            color: $secondary_color;
            font-weight: 700;
            text-decoration: none;
            transition: all 200ms ease-in-out;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}