@import "variables.scss";

.songContainer {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    margin: 1rem 0;
    padding: 0.5rem 0;
    border-radius: 5px;
    background: linear-gradient(to top, 
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.1) 100%);
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
    p {
        text-align: center;
        height: fit-content;
        margin: 0;
        font-size: 1.1rem;
    }
    button {
        margin: auto;
    }
    .play, .pause {
        transform: scale(0.6);
        &:hover {
            cursor: pointer;
            transform: scale(0.7);
        }
    }
}