

.aboutContainer{
    display: flex;
    align-items: center;
    margin-top: 5rem;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 100%);
    .richSelfie {
        position: relative;
        width: 60%;
        height: auto;
        object-fit: contain;
    }
    .aboutTextContainer{
        margin-right: 5%;
        margin-bottom: 3%;
        .aboutHeader {
            font-size: 4rem;
        }
        .aboutText {
            font-size: 1.5rem;
            margin-top: 2rem;
        }
    }
}

@media (max-width: 1000px) {
    .aboutContainer{
        align-items: flex-end;
        .richSelfie {
            width: 50%;
            height: auto;
        }
        .aboutTextContainer{
            .aboutHeader {
                font-size: 3rem;
            }
            .aboutText {
                font-size: 1.2rem;
                margin-top: 1rem;
            }
        }
    }
}
@media (max-width: 600px) {
    .aboutContainer{
        flex-direction: column;
        align-items: center;
        .richSelfie {
            width: 100%;
            height: auto;
        }
        .aboutTextContainer{
            margin: 0 5%;
            margin-bottom: 12%;
            .aboutHeader {
                font-size: 2.5rem;
            }
            .aboutText {
                font-size: 1rem;
                margin-top: 1rem;
            }
        }
    }
}
